import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Flex, Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { kebabCase } from 'lodash'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import LinkExt from '../components/elements/LinkExt'
import StyledMapWithAnInfoBox from '../components/elements/GoogleMap'

const ContactPage = ({ data }) => {
  const page = data.prismicContactpage
  const directory = data.prismicContactpageBodyDirectory

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />

      <Hero>
        <BackgroundImage
          Tag="div"
          fluid={page.data.hero_image.fluid}
          css={`
            padding-top: 180px;
          `}
        >
          <Container py={['12em', '12em', '16em']} />
        </BackgroundImage>
      </Hero>

      <Section id="directory" bg="gray.50">
        <Container>
          <Flex flexWrap="wrap" mx={-4}>
            <Box width={['full']} px={4} mb={12}>
              <Heading as="h1" mb={12}>
                {page.data.title.text}
              </Heading>
              <Text
                dangerouslySetInnerHTML={{
                  __html: page.data.content.html,
                }}
                fontSize="lg"
              />
            </Box>

            <SimpleGrid columns={[1, 1, 2, 3]} spacing={9} px={4}>
              {directory.items.map(
                ({ address, name, phone_number, email_address, website }) => (
                  <Box
                    key={`${kebabCase(name.text)}`}
                    bg="white"
                    p={6}
                    borderColor="gray.100"
                    borderWidth={1}
                  >
                    <Box as="h3">{name.text}</Box>
                    <Box>
                      {phone_number.text && (
                        <a href={`tel:${phone_number.text}`}>
                          <p>{phone_number.text}</p>
                        </a>
                      )}

                      {email_address.text && (
                        <a href={`mailto:${email_address.text}`}>
                          <p>{email_address.text}</p>
                        </a>
                      )}

                      {website ? (
                        <LinkExt to={website.url}>
                          <p>{website.url}</p>
                        </LinkExt>
                      ) : null}

                      <div
                        dangerouslySetInnerHTML={{
                          __html: address.html,
                        }}
                      />
                    </Box>
                  </Box>
                )
              )}
            </SimpleGrid>
          </Flex>
        </Container>
      </Section>

      <Section id="directions" py="0">
        <Flex flexWrap="wrap">
          <Box width={['full']}>
            <StyledMapWithAnInfoBox />
          </Box>
        </Flex>
      </Section>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPage {
    prismicContactpage {
      data {
        meta_description
        meta_title
        content {
          html
        }
        hero_image {
          url
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
      }
    }
    prismicContactpageBodyDirectory {
      id
      items {
        address {
          html
        }
        name {
          text
        }
        phone_number {
          text
        }
        email_address {
          text
        }
        website {
          url
          target
        }
      }
    }
  }
`
