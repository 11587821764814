import React from 'react'

const { compose, withProps, withStateHandlers } = require('recompose')
const { withScriptjs, withGoogleMap, GoogleMap, Marker } = require('react-google-maps')
const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox')

const markerOk = require('../../images/map_icon.png')

const StyledMapWithAnInfoBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyC_yAOVjjihd-FDKV01KxQ9C7D763HYjW8&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 39.644, lng: -79.922 },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={16} defaultCenter={props.center} defaultOptions={{ styles: '' }}>
    <Marker position={{ lat: 39.6443898, lng: -79.9185999 }} onClick={props.onToggleOpen} icon={{ url: markerOk }}>
      {props.isOpen && (
        <InfoBox onCloseClick={props.onToggleOpen} options={{ closeBoxURL: ``, enableEventPropagation: true }}>
          <div
            style={{
              backgroundColor: `white`,
              opacity: 0.8,
              padding: `12px`,
            }}
          >
            <div style={{ fontSize: `12px`, fontColor: `#08233B` }}>
              <p>
                Morgantown Municipal AIrport <br />
                100 Hart Field Rd, Morgantown, WV 26505{' '}
              </p>
              <p>
                <a href="https://www.google.com/maps/place/Morgantown+Municipal+Airport/@39.6443868,-79.9223404,17z/data=!4m8!1m2!2m1!1smorgantown+airport!3m4!1s0x883564b5a9ab360f:0x563ac79140b0c640!8m2!3d39.645296!4d-79.920321">
                  Directions
                </a>
              </p>
            </div>
          </div>
        </InfoBox>
      )}
    </Marker>
  </GoogleMap>
))

export default StyledMapWithAnInfoBox
